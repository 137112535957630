.video {
  position: relative;
}

.video iframe {
  height: 435px;
  width: 775px;
}

.play {
  height: 50px;
  width: 50px;
  z-index: 1;
}

@media (max-width: 576px) {
  .video iframe {
    height: 290px;
    width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 415px) {
  .video iframe {
    height: 212px;
    width: 100%;
  }
}

@media (max-width: 375px) {
  .video iframe {
    height: 190px;
    width: 100%;
  }
}

@media (max-width: 321px) {
  .video iframe {
    height: 170px;
    width: 100%;
  }
}
